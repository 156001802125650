@import "nlib/config";
@import "animations";

.documentsPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: $mobileNavBarHeight;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent 0 $contentDefaultIndent * 2;
  }
  .pagination {
    justify-content: center;
    padding: $contentDefaultIndent * 2 $contentDefaultIndent;
  }
}
