@import "nlib/config";

.filters {
  margin-top: -$contentDefaultIndent * 1.5;
  padding-top: $contentDefaultIndent * 1.5;
  padding-bottom: $contentDefaultIndent * 1.5;
  background-color: $uiWhiteColor;
  &.active {
    position: relative;
    position: sticky;
    z-index: 9;
    top: 0;
    background-color: $uiWhiteColor;
  }
  .container {
    display: flex;
    padding-right: $contentDefaultIndent * 2;
    padding-left: $contentDefaultIndent * 2;
    .title {
      display: flex;
      position: relative;
      flex: auto;
      align-items: center;
      margin-right: $contentDefaultIndent * 2;
      padding-right: 4rem;
      > span {
        display: block;
        overflow: hidden;
        font-size: 1.125rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .search {
      position: absolute;
      top: 0;
      right: 0;
      width: 3rem;
      input {
        padding-left: 2rem;
      }
      input + div {
        margin-left: $contentDefaultIndent / 2;
      }
      &.focused {
        width: 100%;
        input + div {
          margin-left: 0;
        }
      }
    }
  }
  .moreFilters {
    min-width: 3rem;
    border-color: $uiBorderColor;
    svg {
      font-size: 1.125rem;
    }
  }
}
.filtersModal {
  .list {
    .listItem {
      display: flex;
      align-items: center;
      min-height: 3.75rem;
      border-bottom: 0.125rem solid $uiBorderColor;
      &:first-child {
        border-top: 0.125rem solid $uiBorderColor;
      }
    }
  }
  .datePicker {
    width: 100%;
  }
  .select {
    width: 100%;
    .selectToggleButton {
      display: flex;
      align-items: center;
      svg {
        min-width: 1.125rem;
        color: $uiNeutralColor;
        font-size: 1.125rem;
      }
      .title {
        padding-right: $contentDefaultIndent;
        padding-left: $contentDefaultIndent;
        font-size: 1rem;
        font-weight: 500;
      }
      .label {
        margin-left: auto;
        overflow: hidden;
        color: $uiNeutralColor;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.hasSelected {
          color: $uiPrimaryColor;
          font-weight: 500;
        }
      }
    }
  }
  .buttons {
    margin-top: $contentDefaultIndent * 2;
    .button {
      width: 100%;
      svg {
        margin-right: $contentDefaultIndent;
        font-size: 1rem;
      }
      + .button {
        margin-top: $contentDefaultIndent * 2;
      }
    }
  }
}

@media (min-width: $viewportSizeMedium) {
  .filters {
    .search {
      &.focused {
        max-width: 30rem;
      }
    }
  }
}
