@import "nlib/config";

.listItemDetails {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    svg {
      font-size: 1.25rem;
    }
    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .negative {
      color: $uiNegativeColor;
    }
    .warning {
      color: $uiWarningColor;
    }
    .button {
      margin-right: $contentDefaultIndent;
    }
  }
  .content {
    display: flex;
    position: relative;
    flex: auto;
    flex-direction: column;
    .col {
      margin-bottom: $contentDefaultIndent * 2;
    }
    .preview {
      margin-bottom: $contentDefaultIndent * 2;
    }
    .activity {}
  }
}

@media (min-width: $viewportSizeMedium) {
  .listItemDetails {
    .content {
      flex-direction: row;
      .col {
        width: 50%;
        + .col {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
}
