@import "nlib/config";

.documentPreview {
  position: relative;
  width: 100%;
  max-width: $maxMobileContainerWidth / 2;
  min-height: 6rem;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid $uiBorderColor;
  border-radius: $uiBorderRadius;
  background-color: $uiBackgroundColor;
  .container {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      display: block;
      padding-top: 141.42%;
    }
    .image {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
      .icon {
        display: flex;
        position: absolute;
        top: $contentDefaultIndent;
        right: $contentDefaultIndent;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid $uiBorderColor;
        border-radius: $uiBorderRadius;
        background-color: $uiWhiteColor;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px 1px 4px rgba(0, 0, 0, 0.1);
        svg {
          font-size: 1.125rem;
        }
      }
    }
  }
  .preloader {
    font-size: 1.5rem;
  }
  .error {
    color: $uiNegativeColor;
    font-size: 2.5rem;
  }
}
