@import "nlib/config";

.listItem {
  width: 100%;
  margin: 0 auto $contentDefaultIndent;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 0 1px 4px rgba(70, 70, 70, 0.1);
  .header {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5 0;
    .info {
      flex: auto;
      margin-right: $contentDefaultIndent * 2;
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      .negative {
        color: $uiNegativeColor;
      }
      .warning {
        color: $uiWarningColor;
      }
      .neutral {
        color: $uiNeutralColor;
      }
    }
  }
  .timestamp {
    min-width: 5rem;
    margin-right: $contentDefaultIndent;
  }
  .amount {
    font-weight: 500;
  }
  .documentStatus {
    margin-right: $contentDefaultIndent * 2;
    .status {
      &.percents {
        width: 34px;
        text-align: center;
      }
    }
  }
  .vendor {
    display: flex;
    align-items: center;
    max-width: 40%;
    margin-left: auto;
    overflow: hidden;
    white-space: nowrap;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content {
    display: flex;
    padding: $contentDefaultIndent * 1.5;
  }
}
