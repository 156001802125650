@import "nlib/config";

.uploadDocumentsModal {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
  .attachments {
    flex: auto;
  }
  .merge {
    display: flex;
    justify-content: center;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    button {
      width: 100%;
      max-width: 100%;
      padding: $contentDefaultIndent * 1.5 0;
      line-height: 1.25rem;
      &::before,
      &::after {
        content: "";
        display: block;
        flex: 1;
        width: 24px;
        min-width: 4px;
        height: 1px;
      }
      .checkbox {
        overflow: hidden;
        .checkboxLabel {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto $contentDefaultIndent * 2;
    .button {
      + .button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
  .footer {
    display: block;
    button {
      width: fit-content;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .buttons {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $contentDefaultIndent * -1 0;
      .button {
        margin: $contentDefaultIndent * 2 $contentDefaultIndent 0;
      }
    }
  }
}
